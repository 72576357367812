import React from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import './index.css';
import Footer from '../../../components/footer';
import Header from '../../../components/header.js';
import CustomInput from '../../../components/customInput.js';
import DatePicker from "react-datepicker";
import URLs from '../../../constants/Api';
import { ClipLoader } from "react-spinners";
import arrow from '../../../assets/arrow-icon.png';
import xButton from '../../../assets/x-button.png';
import xButton2 from '../../../assets/x-button-2.png';


import MQP from '../../../assets/mini_qp.png';
import QP from '../../../assets/qp.png';
import HP from '../../../assets/hp.png';
import LP from '../../../assets/lp.png';
import FP from '../../../assets/fp.png';

import Cookies from 'universal-cookie';

class BookingOne extends React.Component{

  constructor(props){
    super(props);

    if(this.props.location.state!=undefined){
      this.state = {
        postcode1type:'text',
        postcode2type:'text',
        loadingCountries:true,
        loadingCurrencies:true,
        loadingProducts:true,
        loadingServices:true,
        loadingAddOns:true,
        fromCountry:this.props.location.state[0].fromCountry || '',
        fromPostcode:this.props.location.state[0].fromPostcode || "",
        toCountry:this.props.location.state[0].toCountry || '',
        toPostcode:this.props.location.state[0].toPostcode || "",
        currency:this.props.location.state[0].currency || 'EUR',
        promoCode:this.props.location.state[0].promoCode,
        collectionDate:this.props.location.state[0].collectionDate || new Date(),
        products:['Mini-Pallet','Half-Pallet','Full-Pallet'],
        length:'',
        width:'',
        height:'',
        weight:'',
        availableCountries:[],
        availableCurrencies:[],
        limitedCountries:[{NAME:'Ireland',CODE:'IE'}, {NAME:'United Kingdom',CODE:'UK'}],
        IrelandOnly:[{NAME:'Ireland',CODE:'IE'}],
        products:[],
        services:[],
        orders:[],
        quantityList:[1,2,3,4,5,6],
        quantity:this.props.location.state[0].quantity || 1,
        italyLocalitiesFrom:[],
        italyLocalitiesTo:[],
        localityTo:'',
        localityFrom:'',
      }

      this.defaultValues = this.props.location.state[0];

    }else{
      this.state = {
        postcode1type:'text',
        postcode2type:'text',
        loadingCountries:true,
        loadingCurrencies:true,
        loadingProducts:true,
        loadingServices:true,
        loadingAddOns:true,
        fromCountry:'',
        fromPostcode:"",
        toCountry:'',
        toPostcode:"",
        currency:'EUR',
        promoCode:'',
        collectionDate: (new Date().getHours() >= 16)? new Date((new Date().getTime() + 2*1000*60*60*24)) : new Date((new Date().getTime() + 1000*60*60*24)),
        products:['Mini-Pallet','Half-Pallet','Full-Pallet'],
        length:'',
        width:'',
        height:'',
        weight:'',
        availableCountries:[],
        availableCurrencies:[],
        limitedCountries:[{NAME:'Ireland',CODE:'IE'},{NAME:'United Kingdom',CODE:'UK'}],
        IrelandOnly:[{NAME:'Ireland',CODE:'IE'}],
        products:[],
        services:[],
        orders:[],
        quantityList:[1,2,3,4,5,6],
        quantity:1,
        italyLocalitiesFrom:[],
        italyLocalitiesTo:[],
        localityTo:'',
        localityFrom:'',
      }

      const cookies = new Cookies();
        const query = new URLSearchParams(this.props.location.search);
        const token = query.get('setToken');

        if(token){
          cookies.set('accessToken', token, { expires: new Date(new Date().getTime() + 24*3600*1000)});
          this.setState({mobileApp:true});
        }



    }

    if(this.state.fromCountry == "IT"){
      let localityData = {
        postcode:this.state.fromPostcode.toUpperCase().replaceAll(' ','')
      }

      if(this.state.fromPostcode.length != 7) return;

      fetch(URLs.localitiesURL,{
          method: "POST",
          body:JSON.stringify(localityData),
          headers: {
            'Accept':       'application/json',
            'Content-Type': 'application/json'
          },
          })
          .then((res) => {
            if(res.status == 200){
              res.json().then(async(data)=>{

                console.log(data);

                let localities = [];


                for(let localityIndex = 0;localityIndex < data.length;localityIndex++){
                  localities.push({CODE:data[localityIndex].Locality,NAME:data[localityIndex].Locality});
                }

                this.setState({
                  italyLocalitiesFrom:localities,
                  localityFrom:localities.length == 0 ? '':localities[0].CODE
                })

              })


            }else{
              res.json().then(async(data)=>{

                console.log(data);

              })
            }

          }).catch((error)=>{
            console.log(error);
          })
    }

     if(this.state.toCountry == "IT"){
       let localityData = {
         postcode:this.state.toPostcode.toUpperCase().replaceAll(' ','')
       }

       if(this.state.toPostcode.length != 7) return;

       fetch(URLs.localitiesURL,{
           method: "POST",
           body:JSON.stringify(localityData),
           headers: {
             'Accept':       'application/json',
             'Content-Type': 'application/json'
           },
           })
           .then((res) => {
             if(res.status == 200){
               res.json().then(async(data)=>{

                 console.log(data);

                 let localities = [];


                 for(let localityIndex = 0;localityIndex < data.length;localityIndex++){
                   localities.push({CODE:data[localityIndex].Locality,NAME:data[localityIndex].Locality});
                 }

                 this.setState({
                   italyLocalitiesTo:localities,
                   localityTo:localities.length == 0 ? '':localities[0].CODE
                 })

               })


             }else{
               res.json().then(async(data)=>{

                 console.log(data);

               })
             }

           }).catch((error)=>{
             console.log(error);
           })

     }


    this.pdf_file = React.createRef();
    this.pdf_file_1 = React.createRef();
    this.pdf_file_2 = React.createRef();


  }

  componentDidMount(){
    this.loadData();
    window.scrollTo(0, 0);

    if(this.state.toCountry == "UK" || this.state.fromCountry == "UK"){
      this.setState({customsNoticeOpen:false});
    }

  }

  changeToCountry = (event)=>{

    if(event.target.value == 'UK'){
      this.setState({
        fromCountry:'IE',
        toCountry:event.target.value,
        customsNoticeOpen:false
      })
    }else{
      this.setState({
        toCountry:event.target.value
      })
    }

  }

  changeToPostcode = (event)=>{

      this.setState({
        toPostcode:event.target.value.replaceAll(' ','')
      })


  }

  changeToPostcodeItaly = (event)=>{

      this.setState({
        toPostcode:event.target.value.replaceAll(' ','')
      })

      let localityData = {
        postcode:this.state.toPostcode.toUpperCase().replaceAll(' ','')
      }

      console.log(event.target.value);
      if(this.state.toPostcode.length != 7) return;

      fetch(URLs.localitiesURL,{
          method: "POST",
          body:JSON.stringify(localityData),
          headers: {
            'Accept':       'application/json',
            'Content-Type': 'application/json'
          },
          })
          .then((res) => {
            if(res.status == 200){
              res.json().then(async(data)=>{

                console.log(data);

                let localities = [];


                for(let localityIndex = 0;localityIndex < data.length;localityIndex++){
                  localities.push({CODE:data[localityIndex].Locality,NAME:data[localityIndex].Locality});
                }

                this.setState({
                  italyLocalitiesTo:localities,
                  localityTo:localities.length == 0 ? '':localities[0].CODE
                })

              })


            }else{
              res.json().then(async(data)=>{

                console.log(data);

              })
            }

          }).catch((error)=>{
            console.log(error);
          })



  }

  changeFromPostcodeItaly = async(event)=>{

      await this.setState({
        fromPostcode:event.target.value.replaceAll(' ','')
      })

      let localityData = {
        postcode:this.state.fromPostcode.toUpperCase().replaceAll(' ','')
      }

      console.log(event.target.value);
      if(this.state.fromPostcode.length != 7) return;

      fetch(URLs.localitiesURL,{
          method: "POST",
          body:JSON.stringify(localityData),
          headers: {
            'Accept':       'application/json',
            'Content-Type': 'application/json'
          },
          })
          .then((res) => {
            if(res.status == 200){
              res.json().then(async(data)=>{

                console.log(data);

                let localities = [];


                for(let localityIndex = 0;localityIndex < data.length;localityIndex++){
                  localities.push({CODE:data[localityIndex].Locality,NAME:data[localityIndex].Locality});
                }

                this.setState({
                  italyLocalitiesFrom:localities,
                  localityFrom:localities.length == 0 ? '':localities[0].CODE
                })

              })


            }else{
              res.json().then(async(data)=>{

                console.log(data);

              })
            }

          }).catch((error)=>{
            console.log(error);
          })

  }

  changeFromCountry = (event)=>{

    if(event.target.value == 'UK'){
      this.setState({
        fromCountry:event.target.value,
        toCountry:'IE',
        customsNoticeOpen:false
      })
    }else{
      this.setState({
        fromCountry:event.target.value,
        toCountry:'IE',
      })
    }



  }

  changeFromPostcode = (event)=>{
    this.setState({
      fromPostcode:event.target.value.replaceAll(' ','')
    })
  }

  changeCollectionDate = (date)=>{
    this.setState({
      collectionDate:date
    })
  }

  changeCurrency = (event)=>{
    this.setState({
      currency:event.target.value
    })
  }

  changeUkFormCurrency = (event)=>{
    this.setState({
      UkFormCurrency:event.target.value
    })
  }

  changePromoCode = (event)=>{
    this.setState({
      promoCode:event.target.value
    })
  }

  changeLength = async(event)=>{
    await this.setState({
      length:event.target.value
    });

    this.updateChosenProduct();
  }

  changeWidth = async(event)=>{

    await this.setState({
      width:event.target.value
    });

    this.updateChosenProduct();
  }

  changeHeight = async(event)=>{
    await this.setState({
      height:event.target.value
    });

    this.updateChosenProduct();
  }

  changeWeight = async(event)=>{
    await this.setState({
      weight:event.target.value
    });

    this.updateChosenProduct();
  }

  changeProduct = (event)=>{
    this.setState({
      product:event.target.value,
      length:'',
      width:'',
      height:'',
      weight:'',
    })
  }

  changeService = (event)=>{
    this.setState({
      service:event.target.value
    })

    console.log(event.target.value);
  }

  changeLocalityTo = (event)=>{
    this.setState({
      localityTo:event.target.value
    })

    console.log(event.target.value);
  }


  changeLocalityFrom = (event)=>{
    this.setState({
      localityFrom:event.target.value
    })

    console.log(event.target.value);
  }


  changeAddOn = (event)=>{
    this.setState({
      addOn:event.target.value
    })
  }

  changeQuantity = (event)=>{

    let newProductsList = [];
    let newWeightsList = [];

    for(let index = 0; index < event.target.value; index++){

      if(index < this.state.productsList.length){
        console.log(index);
        newProductsList.push(this.state.productsList[index]);
        newWeightsList.push(this.state.weightsList[index]);
      }else{
        newProductsList.push("NO_SELECTION");
        newWeightsList.push("0");
      }

    }


    this.setState({
      quantity:event.target.value,
      productsList:newProductsList,
      weightsList:newWeightsList
    })

  }


  loadData = async()=>{
    try{


      fetch(URLs.countriesURL,{
          method: "GET",
          headers: {
            'Accept':       'application/json',
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          })
          .then((res) => {
            if(res.status == 200){
              res.json().then((data)=>{

                let fromCountry = (this.state.fromCountry != "")? this.state.fromCountry:'NO_SELECTION';
                let toCountry = (this.state.toCountry != "")? this.state.toCountry:'NO_SELECTION';

                console.log(fromCountry);

                if(fromCountry == 'UK'){
                  toCountry = 'IE';
                }

                console.log(data);
                this.setState({loadingCountries: false, availableCountries:data, fromCountry: fromCountry, toCountry: toCountry});
              })
            }else{

              console.log(res);
              this.setState({
                error:"There was an error loading",
                loadingCountries:false
              });
              return;
            }
        });

        fetch(URLs.currenciesURL,{
            method: "GET",
            headers: {
              'Accept':       'application/json',
              'Content-Type': 'application/x-www-form-urlencoded'
            },
            })
            .then((res) => {
              if(res.status == 200){
                res.json().then(async(data)=>{
                  console.log(this.state.currency);
                  let currency = (this.state.currency != "") ? this.state.currency:data[0].CODE;
                  await this.setState({loadingCurrencies: false, availableCurrencies:data, currency: currency, UkFormCurrency:currency});
                })
              }else{

                console.log(res);
                this.setState({
                  error:"There was an error loading",
                  loadingCurrencies:false
                });
                return;
              }
          });

          fetch(URLs.productsURL,{
              method: "GET",
              headers: {
                'Accept':       'application/json',
                'Content-Type': 'application/x-www-form-urlencoded'
              },
              })
              .then((res) => {
                if(res.status == 200){
                  res.json().then(async(data)=>{
                    data.sort(this.sortProducts);
                    console.log(data);
                    let product = data[0].CODE;

                    for(let count = 0;count < data.length;count++){
                      data[count].LENGTH = data[count].LENGTH * 1000;
                      data[count].WIDTH = data[count].WIDTH * 1000;
                      data[count].HEIGHT = data[count].HEIGHT * 1000;
                    }

                    let quantity = this.state.quantity;
                    let productsList = [];
                    let weightsList = [];
                    let sortedProducts = data.sort(this.sortProducts);

                    sortedProducts.reverse();

                    console.log(sortedProducts);

                    for(let productCount = 0; productCount < quantity;productCount++){
                      productsList.push("NO_SELECTION");
                      weightsList.push("0");
                    }

                    await this.setState({loadingProducts: false, products:sortedProducts, product: product,productsList:productsList, weightsList:weightsList});
                  })
                }else{

                  this.setState({
                    error:"There was an error loading",
                    loadingCurrencies:false
                  });
                  return;
                }
            });


            fetch(URLs.servicesURL,{
                method: "GET",
                headers: {
                  'Accept':       'application/json',
                  'Content-Type': 'application/x-www-form-urlencoded'
                },
                })
                .then((res) => {
                  if(res.status == 200){
                    res.json().then(async(data)=>{
                      let service = data[0].ID;
                      console.log(data);
                      await this.setState({loadingServices: false, services:data, service: service});
                    })
                  }else{

                    console.log(res);
                    this.setState({
                      error:"There was an error loading",
                      loadingServices:false
                    });
                    return;
                  }
              });

              fetch(URLs.addonsURL,{
                  method: "GET",
                  headers: {
                    'Accept':       'application/json',
                    'Content-Type': 'application/x-www-form-urlencoded'
                  },
                  })
                  .then((res) => {
                    if(res.status == 200){
                      res.json().then(async(data)=>{
                        let addOn = data[0].ID;
                        data.reverse();

                        for(let index = 0; index < data.length; index++){
                          data[index].checked = false;
                        }

                        await this.setState({loadingAddOns: false, addOns:data});
                      })
                    }else{

                      console.log(res);
                      this.setState({
                        error:"There was an error loading",
                        loadingServices:false
                      });
                      return;
                    }
                });

    }catch(error){
      alert(error);
    }

  }

  updateAddOns = (index)=>{
    const addOns = this.state.addOns;
    addOns[index].checked = !addOns[index].checked;

    this.setState({addOns:addOns});
  }

  updateChosenProduct = ()=>{

    if(parseInt(this.state.width)>1200 || parseInt(this.state.height)>3000){
      this.setState({
        calculatorError:"Dimensions cannot exceed 3000mm in  width or 1200mm in height!",
        loadingProducts:false
      });
      return;
    }

    if(this.state.weight!="" && this.state.height!="" && this.state.length!="" && this.state.width!=""){
        let productNotFound = true;
        let index = 0;

        while(productNotFound){
          if(index < this.state.products.length){

            let currentProduct = this.state.products[index];

            if((this.state.weight <= currentProduct.WEIGHT) && (this.state.height <= currentProduct.HEIGHT) && (this.state.length <= currentProduct.LENGTH) && (this.state.width <= currentProduct.WIDTH)){
              this.setState({product: currentProduct.CODE});
              productNotFound = false;
              console.log(this.state.height);
              console.log(currentProduct.HEIGHT);
            }else{
              index++;
            }

          }else{
            productNotFound = false;
          }
        }

    }else{
      this.setState({product: null});
    }

  }

  sortProducts = (a,b)=>{

    let comparison = 0;

    const weightOne = parseFloat(a.WEIGHT);
    const weightTwo = parseFloat(b.WEIGHT);

    if(weightOne > weightTwo){
      comparison = 1;
    }else if (weightOne < weightTwo){
      comparison = -1;
    }

    if(comparison == 0){
      let aDimensions = parseFloat(a.LENGTH * a.WIDTH * a.HEIGHT);
      let bDimensions = parseFloat(b.LENGTH * b.WIDTH * b.HEIGHT);


      if(aDimensions > bDimensions){
        comparison = 1;
      }else if(aDimensions < bDimensions){
        comparison = -1;
      }
    }

    return comparison;

  }

  encodeQueryData = (data)=>{
   const ret = [];
   for (let d in data)
     ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
   return ret.join('&');
 }

  submitBooking = async()=>{



    await this.setState({loadingProducts:true});

    let chosenProduct = null;

    for(let index = 0; index<this.state.products.length;index++){
      if(this.state.products[index].CODE == this.state.product){
        chosenProduct = this.state.products[index];
      }
    }

    if(this.state.fromCountry == "IE" && (this.state.fromPostcode.toUpperCase().includes("BT")) && this.state.toCountry == "UK"){
      this.setState({loadingProducts:false, error:"Service currently unavailable – please contact our GB office on 01543 445656 or email sales@translandgroup.co.uk"});
      return;
    }

    if(this.state.toCountry == "IE" && (this.state.toPostcode.toUpperCase().includes("BT")) && this.state.fromCountry == "UK"){
      this.setState({loadingProducts:false, error:"Service currently unavailable – please contact our GB office on 01543 445656 or email sales@translandgroup.co.uk"});
      return;
    }


    if((this.state.fromCountry == "IE" && (!this.state.fromPostcode.toUpperCase().includes("BT")) && this.state.fromPostcode.replace(" ","").length != 7)||(this.state.toCountry == "IE" && (!this.state.toPostcode.includes("BT")) && this.state.toPostcode.replace(" ","").length != 7)){
      this.setState({loadingProducts:false, error:"Irish Eircodes must be 7 digits. E.g. V94 0000"});
      return;
    }

    if((this.state.fromCountry == "IT") && (this.state.fromPostcode.length != 7)){
      this.setState({loadingProducts:false, error:"Italian postcodes must be in the following format: AB12345."});
      return;
    }

    if((this.state.toCountry == "IT") && (this.state.toPostcode.length != 7)){
      this.setState({loadingProducts:false, error:"Italian postcodes must be in the following format: AB12345."});
      return;
    }

    if((this.state.fromCountry == "NL") && (this.state.fromPostcode.length != 6)){
      this.setState({loadingProducts:false, error:"Netherlands postcodes must be in the following format: 1234AB."});
      return;
    }

    if((this.state.toCountry == "NL") && (this.state.toPostcode.length != 6)){
      this.setState({loadingProducts:false, error:"Netherlands postcodes must be in the following format: 1234AB."});
      return;
    }



    for(let palletIndex = 0; palletIndex < this.state.productsList.length; palletIndex++){

      var result = this.state.products.filter(obj => {
          return obj.CODE === this.state.productsList[palletIndex]
      })

      if(result[0] && (parseInt(this.state.weightsList[palletIndex]) <= 0)){
        await this.setState({loadingProducts:false,error:"One or more of your consignments weighs less than 0Kg!"});
        return;
      }

      if(result[0] && (parseInt(this.state.weightsList[palletIndex]) > result[0].WEIGHT)){
        await this.setState({loadingProducts:false,error:"One or more of your consignments weighs too much!"});
        return;
      }

    }


    if(this.state.toPostcode == "" || this.state.fromPostcode == ""){
      await this.setState({loadingProducts:false,error:"Missing one or more postcodes!"});
      return;
    }

    let bookingDate = this.state.collectionDate;
    let months = ['01','02','03','04','05','06','07','08','09','10','11','12'];
    let bookingDateDay = bookingDate.getDate().toString();

    if(bookingDateDay.length == 1){
      bookingDateDay = "0"+bookingDateDay;
    }

    let formattedDate = bookingDate.getFullYear().toString() +"-"+months[bookingDate.getMonth()]+"-"+bookingDateDay;
    let chosenAddons = [];

    for(let addOnsIndex = 0; addOnsIndex < this.state.addOns.length; addOnsIndex++){
      if(this.state.addOns[addOnsIndex].checked){
        chosenAddons.push(this.state.addOns[addOnsIndex].CODE);
      }
    }

    let productsArray = [];

    for(let productIndex = 0; productIndex < this.state.productsList.length; productIndex++){

      if(this.state.productsList[productIndex] == "NO_SELECTION"){
        await this.setState({loadingProducts:false,error:"Please select a product for each pallet"});
        return;
      }

      if(this.state.weightsList[productIndex] == "" || this.state.weightsList[productIndex] == "0"){
        await this.setState({loadingProducts:false,error:"Please enter a valid weight!"});
        return;
      }

      productsArray.push({
        VOLUME:"1",
        PRODUCT_CODE:this.state.productsList[productIndex],
        WEIGHT:this.state.weightsList[productIndex]
      })
    }

    let bookingOneData = {
      PRODUCTS:productsArray,
      COUNTRY_FROM:this.state.fromCountry,
      COUNTRY_TO:this.state.toCountry,
      VOLUME:1,
      POSTCODE_FROM: this.state.fromPostcode.toUpperCase(),
      POSTCODE_TO: this.state.toPostcode.toUpperCase(),
      SERVICE_ID: 1,
      DATE_FROM: formattedDate,
      PROMOTIONS_CODE: this.state.promoCode,
      CURRENCIES_CODE:this.state.currency,
      ADDON_SERVICE_CODE:chosenAddons,
      LOCALITY_FROM:this.state.localityFrom,
      LOCALITY_TO:this.state.localityTo
    }


    console.log(bookingOneData);


    fetch(URLs.bookingOneURL,{
        method: "POST",
        body:JSON.stringify(bookingOneData),
        headers: {
          'Accept':       'application/json',
          'Content-Type': 'application/json'
        },
        })
        .then((res) => {
          if(res.status == 200){
            res.json().then(async(data)=>{


                let responseData = data;
                responseData.CHOSEN_FROM_COUNTRY = this.state.fromCountry;
                responseData.CHOSEN_FROM_POSTCODE = this.state.fromPostcode;
                responseData.CHOSEN_TO_COUNTRY = this.state.toCountry;
                responseData.CHOSEN_TO_POSTCODE = this.state.toPostcode;

                await this.setState({bookingResponse: responseData, error:""});
                this.props.history.push({
                    pathname: '/booking/2',
                    state: responseData
                })


            })
          }else{
            res.json().then(async(data)=>{
              this.setState({
                error:JSON.stringify(data),
                loadingProducts:false
              });
            });
            return;
          }
      });

  }

  getProductImage = ()=>{
    switch(this.state.product){

      case('MQP'):
        return MQP;
      break;

      case('QP'):
        return QP;
      break;

      case('HP'):
        return HP;
      break;

      case('LP'):
        return LP;
      break;

      case('FP'):
        return FP;
      break;

    }
  }

  submitUkForm = async(code)=>{

    let pdf_file = this.pdf_file_main;
    let pdf_file_1= this.pdf_file_1_main;
    let pdf_file_2 = this.pdf_file_2_main;


    let formData = new FormData();

    if(pdf_file){
      formData.append('pdf_file',pdf_file);
    }else{
      await this.setState({
        ukFormError:"Please upload all forms required.",
        loadingProducts:false,
        ukFormOpen:true
      });

      return;
    }


    if(pdf_file_1){
      formData.append('pdf_file_1',pdf_file_1);
    }else{
      await this.setState({
        ukFormError:"Please upload all forms required.",
        loadingProducts:false,
        ukFormOpen:true
      });

      return;
    }

    if(pdf_file_2){
      formData.append('pdf_file_2',pdf_file_2);
    }else{
      await this.setState({
        ukFormError:"Please upload all forms required.",
        loadingProducts:false,
        ukFormOpen:true
      });

      return;
    }




    await this.setState({
      loadingProducts:true
    });



    formData.append('EORI_NO','11111111');
    formData.append('TARIC_CODE','11111111');
    formData.append('GOODS_ORIGIN','11111111');
    formData.append('GOODS_DESC','11111111');
    formData.append('GOODS_VALUE','11111111');
    formData.append('GROSS_WEIGHT','11111111');
    formData.append('NET_WEIGHT','11111111');
    formData.append('BOOKING_CODE', code);

    fetch(URLs.ukFormURL,{
        method: "POST",
        body:formData
        })
        .then((res) => {
          if(res.status == 200){
            res.json().then(async(data)=>{

                console.log(data);

                this.props.history.push({
                    pathname: '/booking/2',
                    state: this.state.bookingResponse
                })

            })
          }else{
            res.json().then(async(data)=>{

              console.log('5');

              this.setState({
                ukFormError:JSON.stringify(data),
                customsNoticeOpen:false,
                loadingProducts:false
              });
            });
            return;
          }
      });
  }

  changeProductAtIndex = (index, value)=>{
    let productsList = this.state.productsList;
    productsList[index] = value;
    this.setState({productsList:productsList})
  }

  changeWeightAtIndex = (index, value)=>{
    let weightsList = this.state.weightsList;
    weightsList[index] = value;
    this.setState({weightsList:weightsList})
  }

  renderUkForm = ()=>{
    if(!this.state.ukFormOpen)return;
  }

  renderCustomsNotice = ()=>{
    if(!this.state.customsNoticeOpen) return;


    return(
      <div className="calculator-box-container">

        <img className="close-calculator-button" style={{zIndex:20}} src={xButton2} onClick={()=>{this.setState({customsNoticeOpen:false, toCountry:"",fromCountry:""})}} />

        <div className="calculator-box">
        <div className="calculator-box-header">Shipments between the Republic of Ireland (ROI) and Great Britain (GB)</div>
        In order to proceed with your online booking to/from GB, you must complete and upload the
        following 3 documents <u>in advance:</u><br/><br/>

          1. Commercial Invoice from the exporter containing all the relevant information to obtain
          export and import Customs Clearance.<a className="template-link" href="/Commercial_Invoice_Template.pdf"><span style={{fontSize:"1.1rem",color:"#e6420b"}}>Commercial Invoice Checklist</span></a><br/>
          2. IE Direct Representative Form authorising Transland to carry out Customs Clearance on
          behalf of the Irish company. You can download this form below.<br/>
          3. GB Direct Representative Form authorising Transland to carry out Customs Clearance on
          behalf of the GB company. You can download this form below.<br/><br/>

        <a className="template-link" href="/Commercial_Invoice_Template.xlsx"><span style={{fontSize:"1.1rem",color:"#e6420b"}}>Download Commercial Invoice Template</span></a>
        <a className="template-link" href="/IE_Template.pdf"><span style={{fontSize:"1.1rem",color:"#7009de", marginLeft:"30px",marginRight:"30px"}}>Download IE Direct Representative Form</span></a>
        <a className="template-link" href="/GB_Template.pdf"><span style={{fontSize:"1.1rem",color:"#e6420b"}}>Download GB Direct Representative Form</span></a><br/><br/><br/>
        <div className="calculator-box-header">PLEASE UPLOAD YOUR DOCUMENTS HERE (MAX. 2MB EACH, PDF ONLY):</div>
        <br/><br/>

        <div className="file-container">
          1.Commercial Invoice (PDF)* <br/>
          <input type="file" ref={this.pdf_file} onChange={(event)=>{
            if(this.pdf_file.current.files[0].size > 2000000){
              alert("Please use a file 2Mb or less in size");
              event.target.value = null;
              return;
            }
            this.pdf_file_main = this.pdf_file.current.files[0];

          }} accept=".pdf"/>
        </div>

        <div className="file-container">
          2.IE Direct Representative Form (PDF)* <br/>
          <input type="file" ref={this.pdf_file_1} onChange={(event)=>{
            if(this.pdf_file_1.current.files[0].size > 2000000){
              alert("Please use a file 2Mb or less in size");
              event.target.value = null;
              return;
            }
            this.pdf_file_1_main = this.pdf_file_1.current.files[0];

          }} accept=".pdf"/>
        </div>

        <div className="file-container">
          3.GB Direct Representative Form (PDF)* <br/>
          <input type="file" ref={this.pdf_file_2} onChange={(event)=>{
            if(this.pdf_file_2.current.files[0].size > 2000000){
              alert("Please use a file 2Mb or less in size");
              event.target.value = null;
              return;
            }
            this.pdf_file_2_main = this.pdf_file_2.current.files[0];

          }} accept=".pdf"/>
        </div>
        <br/><br/>

        <b>We <u>CANNOT ACCEPT</u> online bookings to/from GB for the consignment types listed below – any such bookings will be cancelled and refunded.
Goods of food, animal, wood or plant origin Second-hand machinery Personal effects Hazardous goods (this applies for all routes)
        </b>
        <br/><br/>
        Online bookings to/from GB are subject to an additional Customs Clearance charge of €90.00 per consignment up to 3 Commodity Codes. After your online
        booking has been confirmed, our Customs Department will contact the importer regarding import VAT / Duty payment. For Customs-related queries, please view
        our <a href="../../uk-domestic">Brexit page</a> or email <a href="mailto:customs@translandgroup.com">customs@translandgroup.com</a>.
        <br/><br/>
        <input type="checkbox" id="checkbox2" name="checkbox2" checked={this.state.customs1} onChange={(event)=>{this.setState({customs1:!this.state.customs1})}}></input>
        <span > I confirm that the information uploaded is complete and correct, and understand that any
incomplete / incorrect information may result in delays and / or additional charges.</span>
        <br/>
        <input type="checkbox" id="checkbox3" name="checkbox3" checked={this.state.customs2} onChange={(event)=>{this.setState({customs2:!this.state.customs2})}}></input>
        <span>I understand that the eventual delivery date of my consignment will be dependent on
Customs Clearance authorisation and release, which is outside the control of Transland.</span>
        <br/>
        <input type="checkbox" id="checkbox4" name="checkbox4" checked={this.state.customs3} onChange={(event)=>{this.setState({customs3:!this.state.customs3})}}></input>
        <span >I confirm that my consignment does not contain goods of food, animal, wood or plant origin,
second-hand machinery, personal effects or hazardous goods.</span>

        <div className="uk-form-error">{this.state.ukFormError}</div> <br/>

        <div className="uk-form-submit-button" onClick={this.submitCustomsNotice}>
          Proceed to booking
        </div>

        </div>

      </div>
    )
  }

  submitCustomsNotice = ()=>{
    if(!this.state.customs1 || !this.state.customs2 || !this.state.customs3){
      this.setState({ukFormError:"Please check all boxes above."})
    }else if(!this.pdf_file_main || !this.pdf_file_1_main || !this.pdf_file_2_main){
      this.setState({ukFormError:"Please submit all required forms."})
    }else{
      this.setState({customsNoticeOpen:false});
    }
  }

  getInputColor = (inputWeight, maxWeight)=>{
    return (parseInt(inputWeight) > parseInt(maxWeight));
  }

  renderCalculator = ()=>{
    if(!this.state.calculatorOpen) return;

    return(
      <div className="calculator-box-container">
        <img className="close-calculator-button" src={xButton2} onClick={()=>{this.setState({calculatorOpen:false})}} />

        <div className="calculator-box">
          <div className="calculator-column-1">
            <br/>
            <div className="calculator-inputs-container">
              <CustomInput type={'number'}  value={this.state.length}  onChange={this.changeLength} name={'Product Length (millimeters)'} id={'country2'} options={this.availableCountries}></CustomInput>
              <CustomInput type={'number'} value={this.state.width} onChange={this.changeWidth} name={'Product Width (millimeters)'} id={'date'}></CustomInput>
              <CustomInput type={'number'} value={this.state.height} onChange={this.changeHeight} name={'Product Height (millimeters)'} id={'promotionCode'}></CustomInput>
              <CustomInput type={'number'} value={this.state.weight} onChange={this.changeWeight} name={'Product Weight (kg)*'} id={'promotionCode'}></CustomInput>
            </div>

            <div className="calculator-notice">
              Note: Consignments cannot exceed 3000mm in width or 1200mm in height.
            </div>
          </div>

          <div className="calculator-divider"></div>

          <div className="calculator-column-2">
                <div className="calculator-result">
                  Recommended Product: <br/>
                  <img className="calculator-result-image" src={this.getProductImage()} />
                </div>
          </div>
        </div>

      </div>
    )
  }

  renderToPostcodeSection = ()=>{
    if(this.state.toCountry == "IT"){
      return(
        <div>
          <CustomInput type={this.state.postcode2type} value={this.state.toPostcode} onChange={this.changeToPostcodeItaly} name={'Delivery Eircode/ Postcode*'} id={'postcode2'}></CustomInput>
          <CustomInput type={'dropdown'} value={this.state.localityTo} onChange={this.changeLocalityTo} name={'Locality*'} id={'country1'} options={this.state.italyLocalitiesTo}></CustomInput>
        </div>
      )
    }

    return(
      <CustomInput type={this.state.postcode2type} value={this.state.toPostcode} onChange={this.changeToPostcode} name={'Delivery Eircode/ Postcode*'} id={'postcode2'}></CustomInput>
    )
  }

  renderFromPostcodeSection = ()=>{
    if(this.state.fromCountry == "IT"){
      return(
        <div>
          <CustomInput type={this.state.postcode2type} value={this.state.fromPostcode} onChange={this.changeFromPostcodeItaly} name={'Collection Eircode/ Postcode*'} id={'postcode2'}></CustomInput>
          <CustomInput type={'dropdown'} value={this.state.localityFrom} onChange={this.changeLocalityFrom} name={'Locality*'} id={'country1'} options={this.state.italyLocalitiesFrom}></CustomInput>
        </div>
      )
    }

    return(
      <CustomInput type={this.state.postcode2type} value={this.state.fromPostcode} onChange={this.changeFromPostcode} name={'Collection Eircode/ Postcode*'} id={'postcode2'}></CustomInput>
    )
  }

  renderPostcodeFromWarning = ()=>{
    switch(this.state.fromCountry){
      case("IT"):
        return(
          <div className="postcode-warning">
            Enter postcodes for Italy in format AB12345 and select a locality.
          </div>
        )
      break;

      case("NL"):
        return(
          <div className="postcode-warning">
            Enter postcodes for Netherlands in format 1234AB.
          </div>
        )
      break;

    }
  }

  renderPostcodeToWarning = ()=>{
    if(this.state.toCountry == this.state.fromCountry) return;

    switch(this.state.toCountry){
      case("IT"):
        return(
          <div className="postcode-warning">
            Enter postcodes for Italy in format AB12345 and select a locality.
          </div>
        )
      break;

      case("NL"):
        return(
          <div className="postcode-warning">
            Enter postcodes for Netherlands in format 1234AB.
          </div>
        )
      break;

    }

  }

  renderPastOrders = ()=>{
    if(this.state.orders.length == 0) return;

    return(
      <div>
      <CustomInput type={'autofillCollection'} value={this.state.autofillCollection} onChange={this.changeAutofillCollection} name={'Autofill Collection'} id={'country1'} options={this.state.orders}></CustomInput>
      <CustomInput type={'autofillDelivery'}  value={this.state.autofillDelivery}  onChange={this.changeAutofillDelivery} name={'Autofill Delivery*'} id={'country2'} options={this.state.orders}></CustomInput>
      </div>
    )
  }

  render(){
    if(!this.state.loadingCountries && !this.state.loadingCurrencies && !this.state.loadingProducts && !this.state.loadingServices && !this.state.loadingAddOns){
      return(
        <div>
          <Header baseContext={this}></Header>
          {this.renderCustomsNotice()}
          {this.renderUkForm()}
          <div className="content">
            <div className="booking-1-booking-form-1">
              <div className="booking-form-1-header">
              Booking Details
              {this.renderPostcodeFromWarning()}
              {this.renderPostcodeToWarning()}
              </div>

              <div className="main-booking-form-columns">
                <div className="booking-1-main-booking-form-column-1">
                  <CustomInput type={'dropdown'} value={this.state.fromCountry} onChange={this.changeFromCountry} name={'Collection Country*'} id={'country1'} options={this.state.availableCountries.sort().reverse()}></CustomInput>
                  <CustomInput type={'dropdown'}  value={this.state.toCountry}  onChange={this.changeToCountry} name={'Delivery Country*'} id={'country2'} options={(this.state.fromCountry!="IE")?this.state.IrelandOnly:((this.state.fromCountry=="IE")? this.state.availableCountries.sort().reverse():this.state.limitedCountries.sort().reverse())}></CustomInput>
                  <CustomInput type={'date'} value={this.state.collectionDate} onChange={this.changeCollectionDate} name={'Collection Date*'} id={'date'}></CustomInput>
                  <CustomInput type={'text'} value={this.state.promoCode} onChange={this.changePromoCode} name={'Promotion Code'} id={'promotionCode'}></CustomInput>
                </div>

                <div className="booking-1-main-booking-form-column-2">
                  {this.renderFromPostcodeSection()}
                  {this.renderToPostcodeSection()}
                  <CustomInput type={'dropdown'} name={'Currency*'} value={this.state.currency} onChange={this.changeCurrency} id={'currency'} options={this.state.availableCurrencies}></CustomInput>
                  <CustomInput type={'quantity-dropdown'} name={'Number of Pallets*'} value={this.state.quantity} onChange={this.changeQuantity} id={'currency'} options={this.state.quantityList}></CustomInput>
                </div>

              </div>

            </div>

            <div className="booking-1-booking-form-2">
              <div className="booking-form-1-header">Consignment Details</div>
              <div className="booking-1-main-booking-form-column-1">

              {
                this.state.productsList.map((product, index)=>{

                  var result = this.state.products.filter(obj => {
                      return obj.CODE === this.state.productsList[index]
                })


                  return(
                    <div>
                      <CustomInput type={'products-dropdown'} name={'Pallet '+(index + 1)+' Product'} value={this.state.productsList[index]} onChange={(event)=>{this.changeProductAtIndex(index, event.target.value)}} id={'products'} options={this.state.products}></CustomInput>
                      <CustomInput type={'text'} name={'Pallet '+(index + 1)+' Weight (Kg) MAX weight for chosen size: '+(result[0]? result[0].WEIGHT+' Kg':'')} value={this.state.weightsList[index]} red={this.getInputColor(this.state.weightsList[index], result[0]?result[0].WEIGHT : 0)} onChange={(event)=>{this.changeWeightAtIndex(index, event.target.value)}} id={'products'} options={this.state.products}></CustomInput>
                    </div>
                  )
                  })
              }

              </div>

              <div className="main-booking-form-column-2">
              </div>

              <div className="add-ons-header">
                  <b>PLEASE NOTE:</b>
                  <br/>Standard collection / delivery hours are as follows:<br/>
                  <tr className="price-display">
                    <td style={{width:"50%"}}>Ireland: </td>
                    <td style={{textAlign:"left", width:"50%"}}>9:00 AM – 5:00 PM</td>
                  </tr>
                  <tr className="price-display">
                    <td style={{width:"50%"}}>UK: </td>
                    <td style={{textAlign:"left", width:"50%"}}>9:00 AM – 5:00 PM</td>
                  </tr>
              </div>

              <div className="booking-1-error">{this.state.error}</div>

              <div className="main-booking-1-form-submit-button" onClick={this.submitBooking}>Submit
                <img src={arrow} className="home-form-arrow-icon" ></img>
              </div>
            </div>

            <div className="pallets-information-container">
                <div className="pallet-images-header">Pallet Sizes (QP & HP MUST BE STACKABLE BOTH WAYS)</div>

                <div className="pallet-images-container">
                  <div className="pallet-image-box">
                    <img className="pallet-image" src={FP} />
                    <div className="pallet-image-filter"></div>
                  </div>

                  <div className="pallet-image-box">
                    <img className="pallet-image" src={HP} />
                    <div className="pallet-image-filter"></div>
                  </div>

                  <div className="pallet-image-box">
                    <img className="pallet-image" src={QP} />
                    <div className="pallet-image-filter"></div>
                  </div>
                </div>
          </div>

        </div>

          <br/>
          <Footer mobileApp={this.state.mobileApp}></Footer>
        </div>
      )
    }else{
      return(
        <div className="loading-page-container">
          <div className="loading-page-loader-container">
            <ClipLoader
              size={160}
              color={'#FFFFFF'}
              loading={true}
            />
          </div>
        </div>
      )
    }

  }

}

export default BookingOne;
