import React from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import './index.css';
import Footer from '../../components/footer';
import Header from '../../components/header.js';
import FormInput from '../../components/formInput.js';
import URLs from '../../constants/Api';
import { ClipLoader } from "react-spinners";

import arrow from '../../assets/arrow-icon.png';
import watermark from '../../assets/watermark.png';

class Tracking extends React.Component{

  constructor(props){
    super(props);

    this.state = {
                  loading:false,
                  loaded:false,
                  error:'',
                  trackingNumber:'',

                  collectionName:'',
                  collectionAddress:'',
                  collectionCity:'',
                  collectionInstructions:'',
                  collectionPhone:'',
                  collectionPostcode:'',
                  collectionCountry:'',

                  currencySymbols:{GBP:'£', EUR:'€'},
                  apikey:"Dj4Pvz9yTmxqj23gPjk1%2FKahZNrATTtB5Q7Xc6dNOTs%3D"
                };

  }

  componentDidMount = async()=>{
    window.scrollTo(0, 0);

            const query = new URLSearchParams(this.props.location.search);
            const token = query.get('TRACKING')

            if(this.props.location.state != null){
              await this.setState({trackingNumber:this.props.location.state.number});
              this.loadTrackingDetails();
            }else if(token){
              await this.setState({trackingNumber:token});
              this.loadTrackingDetails();
            }

  }

  encodeQueryData = (data)=>{
   const ret = [];
   for (let d in data)
     ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
   return ret.join('&');
 }


  loadTrackingDetails = async()=>{


    await this.setState({loading:true});

    let trackingData = {
      tracking_id:this.state.trackingNumber
    }







    fetch(URLs.trackingURL,{
        method: "POST",
        body:this.encodeQueryData(trackingData),
        headers: {
          'Accept':       'application/json',
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        })
        .then((res) => {
          if(res.status == 200){
            res.json().then((data)=>{
              console.log(JSON.parse(data));
              document.location = JSON.parse(data)['Detail']['Data'][0]['TrackURL'];
            })
          }else{

            console.log(res);
            this.setState({
              error:"There was an error loading your details.",
              loading:false
            });
            return;
          }
      }).catch((error)=>{
        console.log(error);
        this.setState({
          error:"There was an error loading your details.",
          loading:false
        });
        return;
      });


  }

  render(){

    if(this.state.loaded){
      return(
        <div>
          <Header></Header>
          <div className="tracking-summary">
            <div className="tracking-summary-header">Tracking Summary</div>
            <div className="tracking-content">

            </div>
          </div>
          <Footer></Footer>
        </div>
      )
    }else if(!this.state.loading){

      return(
        <div>
          <Header></Header>

          <div className="background-content">
            <img src={watermark} className="watermark-background" />
            <div className="client-tracking-content">
              <div className="tracking-welcome-header">
              </div>

              <div className="tracking-form">
                <div className="tracking-form-header">Tracking</div>

                <FormInput type={'text'} name={'Tracking Number'} value={this.state.trackingNumber} onChange={(event)=>{this.setState({trackingNumber:event.target.value})}} id={'trackingNumber'}></FormInput>
                <div className="tracking-error">{this.state.error}</div>
                <div className="tracking-submit-button" onClick={this.loadTrackingDetails}>Track
                  <img src={arrow} className="tracking-form-arrow-icon" ></img>
                </div>
                <br/>
              </div>
            </div>
          </div>
          <Footer></Footer>
        </div>
      )

    }else{
      return(
        <div className="loading-page-container">
          <div className="loading-page-loader-container">
            <ClipLoader
              size={160}
              color={'#FFFFFF'}
              loading={true}
            />
          </div>
        </div>
      )
    }

  }

}

export default Tracking;
