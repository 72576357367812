import React from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import './index.css';
import Footer from '../../../components/footer';
import Header from '../../../components/header.js';
import CustomInput from '../../../components/customInput.js';
import DatePicker from "react-datepicker";
import URLs from '../../../constants/Api';
import { ClipLoader } from "react-spinners";

import arrow from '../../../assets/arrow-icon.png';
import UnavailableIcon from '../../../assets/unavailable.png';
import AvailableIcon from '../../../assets/available.png';

import Cookies from 'universal-cookie';

class BookingThree extends React.Component{

  constructor(props){
    super(props);



    if(!this.props.location.state){
      this.props.history.push({
          pathname: '/booking/1'
      });

      this.state = {loadingCountries:true};
    }else{
      this.state = {
        postcode1type:'text',
        postcode2type:'text',
        dataId:this.props.location.state.POST_DATA_ID,
        estimatedArrival:this.props.location.state.DAY.ARRIVAL[0],
        priceNoVat:this.props.location.state['PRICE_NO_VAT'],
        chosenFromCountry:this.props.location.state.CHOSEN_FROM_COUNTRY,
        chosenFromPostcode:this.props.location.state.CHOSEN_FROM_POSTCODE,
        chosenToCountry:this.props.location.state.CHOSEN_TO_COUNTRY,
        chosenToPostcode:this.props.location.state.CHOSEN_TO_POSTCODE,
        loading:true,
        loadingCountries:true,
        error:'',
        customerEmail:'',
        customerEmailConfirmation:'',
        customerCompany:'',
        customerName:'',
        customerPhone:'',
        fromCity:'',
        fromAddress1:'',
        fromAddress2:'',
        fromInstructions:'',

        toName:'',
        toCity:'',
        toAddress1:'',
        toAddress2:'',
        toCompany:'N/A',
        toPhone:'',
        toPhoneName:'',

        billingName:'',
        billingCity:'',
        billingAddress1:'',
        billingAddress2:'',
        billingCountry:'NO_SELECTION',
        billingPostcode:'',
        billingPhone:'',
        billingPhoneName:'',
        billingVAT:'',

        tc1:true,
        tc2:false,
        tc3:false,
        tc4:false,
        tailLiftNeeded:false,
        isLoggedIn:false,
        loginName:'',

        billingSameAsBooking: false,
        orders:[],
        ordersTo:[],
        ordersFrom:[],

        saveAddress:true
      }
    }


  }

  componentDidMount(){
    if(this.props.location.state){
      this.loadData();
      window.scrollTo(0, 0);
      console.log(this.props.location.state);
    }
  }

  setDate = (date)=>{
    this.setState({
      chosenDate:date
    })
  }


  loadData = async()=>{

    const cookies = new Cookies();

    if(cookies.get('accessToken')){
      fetch(URLs.ordersURL,{
          method: "GET",
          headers: {
            'Accept':       'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': 'Bearer '+cookies.get('accessToken')
          },
          })
          .then((res) => {
            console.log('3');
            if(res.status == 200){
              res.json().then(async(data)=>{

                let ordersArray = [];
                let fromAutofillPostcodes = [];
                let toAutofillPostcodes = [];
                let ordersFrom = [];
                let ordersTo = [];

                for(let i = 0;i < data.length;i++){

                  if(data[i]['INFO']['booking']['POST_3']['SAVE_ADDRESS']){
                    ordersArray.push(data[i]);

                    if(data[i]['INFO']['booking']['POST_3']['TO']['POSTCODE'].replaceAll(' ','').toUpperCase() == this.state.chosenToPostcode.replaceAll(' ','').toUpperCase() && !toAutofillPostcodes.includes(data[i]['INFO']['booking']['POST_3']['TO']['POSTCODE'].replaceAll(' ','').toUpperCase())){
                      toAutofillPostcodes.push(data[i]['INFO']['booking']['POST_3']['TO']['POSTCODE'].replaceAll(' ','').toUpperCase());
                      ordersTo.push(data[i]);
                    }

                    if(data[i]['INFO']['booking']['POST_3']['FROM']['POSTCODE'].replaceAll(' ','').toUpperCase() == this.state.chosenFromPostcode.replaceAll(' ','').toUpperCase() && !fromAutofillPostcodes.includes(data[i]['INFO']['booking']['POST_3']['FROM']['POSTCODE'].replaceAll(' ','').toUpperCase())){
                      fromAutofillPostcodes.push(data[i]['INFO']['booking']['POST_3']['FROM']['POSTCODE'].replaceAll(' ','').toUpperCase());
                      ordersFrom.push(data[i]);
                    }

                  }
                }
                await this.setState({orders:ordersArray, ordersFrom:ordersFrom, ordersTo:ordersTo, loadingOrders:false});
                console.log(data);
              })
            }else{
              console.log('4');
              res.json().then(async(data)=>{
                console.log(data);
              })
              this.setState({
                loadingOrders:false
              });
              return;
            }
        });
    }


    fetch(URLs.countriesURL,{
        method: "GET",
        headers: {
          'Accept':       'application/json',
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        })
        .then((res) => {
          if(res.status == 200){
            res.json().then((data)=>{

              let senderCountry = data[0].CODE;
              let receiverCountry = data[0].CODE;

              console.log(data);
              this.setState({loadingCountries: false, availableCountries:data, receiverCountry: receiverCountry});
            })
          }else{

            console.log(res);
            this.setState({
              error:"There was an error loading",
              loadingCountries:false
            });
            return;
          }
      });


  }

  autofillBillingDetailsFromCollection = ()=>{
    this.setState({
      billingName:this.state.customerName,
      billingCity:this.state.fromCity,
      billingAddress1:this.state.fromAddress1,
      billingAddress2:this.state.fromAddress2,
      billingPhone:this.state.customerPhone,
      billingCountry:this.state.chosenFromCountry,
      billingPostcode:this.state.chosenFromPostcode,
      billingPhoneName:this.state.customerCompany
    })
  }

  autofillBillingDetailsFromDelivery = ()=>{
    this.setState({
      billingName:this.state.toName,
      billingCity:this.state.toCity,
      billingAddress1:this.state.toAddress1,
      billingAddress2:this.state.toAddress2,
      billingPhone:this.state.toPhone,
      billingCountry:this.state.chosenToCountry,
      billingPostcode:this.state.chosenToPostcode,
      billingPhoneName:this.state.toContactName
    })
  }



  changeDetails = (details, data)=>{

    let string = '';

    switch(details){
      case('sender_name'):
        string = data.replace(/[^0-9a-z]/gi, ' ');
        string = string.replace(/[0-9]/g, ' ');
        this.setState({customerName: string});
      break;

      case('sender_company'):
        string = data.replace(/[^0-9a-z]/gi, ' ');
        string = string.replace(/[0-9]/g, ' ');
        this.setState({customerCompany: string});
      break;

      case('sender_email'):
        this.setState({customerEmail: data});
      break;

      case('sender_email_confirmation'):
        this.setState({customerEmailConfirmation: data});
      break;

      case('sender_phone'):
        this.setState({customerPhone: data.replaceAll(/[^0-9\.]+/g, ' ')});
      break;

      case('receiver_name'):
        string = data.replace(/[^0-9a-z]/gi, ' ');
        string = string.replace(/[0-9]/g, ' ');
        this.setState({toName: string});
      break;

      case('receiver_contact_name'):
        string = data.replace(/[^0-9a-z]/gi, ' ');
        string = string.replace(/[0-9]/g, ' ');
        this.setState({toContactName: string});
      break;

      case('receiver_city'):
        this.setState({toCity: data});
      break;

      case('receiver_address_1'):
        this.setState({toAddress1: data});
      break;

      case('receiver_address_2'):
        this.setState({toAddress2: data});
      break;


      case('receiver_phone'):
        this.setState({toPhone: data.replaceAll(/[^0-9\.]+/g, ' ')});
      break;

      case('billing_name'):
        this.setState({billingName: data});
      break;

      case('billing_phone_name'):
        this.setState({billingPhoneName: data});
      break;

      case('billing_city'):
        this.setState({billingCity: data});
      break;

      case('billing_address_1'):
        this.setState({billingAddress1: data});
      break;

      case('billing_address_2'):
        this.setState({billingAddress2: data});
      break;

      case('billing_postcode'):
        this.setState({billingPostcode: data});
      break;

      case('billing_phone'):
        this.setState({billingPhone: data.replaceAll(/[^0-9\.]+/g, ' ')});
      break;

      case('billing_vat'):
        this.setState({billingVAT: data});
      break;

      case('sender_country'):
        this.setState({senderCountry: data});
      break;

      case('to_company'):
        string = data.replace(/[^0-9a-z]/gi, ' ');
        string = string.replace(/[0-9]/g, ' ');
        this.setState({toCompany: string});
      break;

      case('from_city'):
        this.setState({fromCity: data});
      break;

      case('from_address_1'):
        this.setState({fromAddress1: data});
      break;

      case('from_address_2'):
        this.setState({fromAddress2: data});
      break;

      case('from_instructions'):
        this.setState({fromInstructions: data});
      break;

      case('delivery_instructions'):
        this.setState({deliveryInstructions: data});
      break;

      case('tc1'):
        this.setState({tc1: !this.state.tc1});
      break;

      case('tc2'):
        this.setState({tc2: !this.state.tc2});
      break;

      case('tc3'):
        this.setState({tc3: !this.state.tc3});
      break;

      case('tc4'):
        this.setState({tc4: !this.state.tc4});
      break;

      case('saveAddress'):
        this.setState({saveAddress: !this.state.saveAddress});
      break;

      case('tail_lift_needed'):
        this.setState({tailLiftNeeded: !this.state.tailLiftNeeded});
      break;

    }

  }


  encodeQueryData = (data)=>{
   const ret = [];
   for (let d in data)
     ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
   return ret.join('&');
 }

  submitDetails = async()=>{


    if(this.state.customerName == "" || this.state.customerEmail == "" || this.state.customerCompany == "" || this.state.fromCity == "" || this.state.fromAddress == ""){
      this.setState({error:"You are missing some details!"});
      return;
    }else if(this.state.billingName == "" || this.state.billingName.length == 0 || this.state.billingCity == "" || this.state.billingAddress == "" || this.state.billingPostcode == "" || this.state.billingPhone == "" || this.state.billingPhoneName == "" || this.state.billingCountry == "NO_SELECTION"){
      this.setState({error:"You are missing billing details!"});
      return;
    }else if(this.state.toName == "" || this.state.toContactName == "" || this.state.toCity == "" || this.state.toAddress == ""){
      this.setState({error:"You are missing receiver details!"});
      return;
    }else if(!this.state.tc1){
      this.setState({error:"Please accept the privacy agreement!"});
      return;
    }else if(!this.state.tc2){
      this.setState({error:"Please confirm your consignmnet is within the maximum dimensions / weights specified"});
      return;
    }else if(!this.state.tc3){
      this.setState({error:"Please agree to all the conditions!"});
      return;
    }else if(!this.state.tc4){
      this.setState({error:"Please agree to all the conditions!"});
      return;
    }

    await this.setState({loadingCountries:true});



    let bookingThreeData = {
      POST_DATA_ID:this.state.dataId,
      CUSTOMER_EMAIL:this.state.customerEmail,
      CUSTOMER_COMPANY:this.state.customerCompany,
      CUSTOMER_NAME:this.state.customerName,
      CUSTOMER_PHONE:this.state.customerPhone,
      CUSTOMER_EMAIL_C:this.state.customerEmail,
      FROM_NAME:this.state.customerName,
      FROM_CITY:this.state.fromCity,
      FROM_ADDRESS:this.state.fromAddress1+", "+this.state.fromAddress2,
      FROM_PHONE:this.state.customerPhone,
      FROM_INSTRUCTIONS: this.state.fromInstructions+"[***] Delivery Instructions: "+(this.state.deliveryInstructions || ""),
      TO_NAME:this.state.toContactName+" Company: "+this.state.toName,
      TO_CITY:this.state.toCity,
      TO_ADDRESS:this.state.toAddress1+","+this.state.toAddress2,
      TO_PHONE:this.state.toPhone,
      BILL_NAME:this.state.billingName,
      BILL_CITY:this.state.billingCity,
      BILL_ADDRESS:this.state.billingAddress1+","+this.state.billingAddress2,
      BILL_PHONE:this.state.billingPhone,
      BILL_PHONE_NAME:this.state.billingPhoneName,
      BILL_COUNTRIES_CODE:this.state.billingCountry,
      BILL_POSTCODE:this.state.billingPostcode,
      BILL_VAT: this.state.billingVAT,
      TC_1: (this.state.tc1 ? 1:0),
      TC_2: (this.state.tc2 ? 1:0),
      TAILLIFT: (this.state.tailLiftNeeded ? 1:0),
      SAVE_ADDRESS:this.state.saveAddress
    }


    fetch(URLs.bookingThreeURL,{
        method: "POST",
        body:this.encodeQueryData(bookingThreeData),
        headers: {
          'Accept':       'application/json',
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        })
        .then((res) => {
          if(res.status == 200){
            res.json().then(async(data)=>{
              let newData = data;
              newData['expectedArrival'] = this.state.estimatedArrival;
              newData['PRICE_NO_VAT'] = this.state.priceNoVat;
              await this.setState({bookingResponse:newData, error:""});
              this.props.history.push({
                  pathname: '/booking/4',
                  state: newData
              })
            })
          }else{
            res.json().then(async(data)=>{
              console.log(data);
            });
            this.setState({
              error:"There was an error submitting your data. Please check that your email, phone numbers and other data is valid.",
              loadingCountries:false
            });
            return;
          }
      });








  }

  changeAutofillCollection = (index)=>{

    if(index == 'NO_SELECTION') return;

    this.setState({
        customerName:this.state.ordersFrom[index]['INFO']['booking']['POST_3']['FROM']['NAME'],
        fromAddress1:this.state.ordersFrom[index]['INFO']['booking']['POST_3']['FROM']['ADDRESS'],
        fromAddress2:'',
        fromCity:this.state.ordersFrom[index]['INFO']['booking']['POST_3']['FROM']['CITY'],
        customerCompany:this.state.ordersFrom[index]['INFO']['CUSTOMER']['NAME'],
        customerPhone:this.state.ordersFrom[index]['INFO']['booking']['POST_3']['FROM']['PHONE'],
        fromInstructions:this.state.ordersFrom[index]['INFO']['booking']['POST_3']['FROM']['INSTRUCTIONS'].split("[***] Delivery Instructions:")[0],
        customerEmail:this.state.ordersFrom[index]['INFO']['booking']['POST_3']['FROM']['EMAIL']
    })
  }

  changeAutofillDelivery = (index)=>{
    if(index == 'NO_SELECTION') return;

    this.setState({
        toName:this.state.ordersTo[index]['INFO']['booking']['POST_3']['TO']['NAME'].split(" Company: ")[1],
        toAddress1:this.state.ordersTo[index]['INFO']['booking']['POST_3']['TO']['ADDRESS'],
        toAddress2:'',
        toCity:this.state.ordersTo[index]['INFO']['booking']['POST_3']['TO']['CITY'],
        toContactName:this.state.ordersTo[index]['INFO']['booking']['POST_3']['TO']['NAME'].split(" Company: ")[0],
        toPhone:this.state.ordersTo[index]['INFO']['booking']['POST_3']['TO']['PHONE'],
        deliveryInstructions:this.state.ordersTo[index]['INFO']['booking']['POST_3']['FROM']['INSTRUCTIONS'].split("[***] Delivery Instructions:")[1]
    })


  }

  renderPastOrders = ()=>{
    if(this.state.orders.length == 0) return;

    return(
      <div>
      <CustomInput type={'autofillCollection'} value={this.state.autofillCollection} onChange={(event)=>{this.changeAutofillCollection(event.target.value)}} name={'Autofill Collection'} id={'country1'} options={this.state.ordersFrom}></CustomInput>
      <CustomInput type={'autofillDelivery'}  value={this.state.autofillDelivery}  onChange={(event)=>{this.changeAutofillDelivery(event.target.value)}} name={'Autofill Delivery*'} id={'country2'} options={this.state.ordersTo}></CustomInput>
      </div>
    )
  }



  render(){
    if(!this.state.loadingCountries){
      return(
        <div>
          <Header></Header>

          <div className="content">
            <div className="booking-form-3">
              <div className="main-booking-form-header-3">Booking Details</div>

              {this.renderPastOrders()}

                <br/>  <br/>

              <div className="main-booking-form-columns">

                <div className="booking-3-main-booking-form-column-1">

                  <b style={{color:"#787878"}}>Collection:</b><br/>


                  <CustomInput type={'text'} value={this.state.customerName} onChange={(event)=>{this.changeDetails('sender_name', event.target.value)}} name={'Name/ Company*'} id={'promotionCode'}></CustomInput>
                  <CustomInput type={'text'} value={this.state.fromAddress1} onChange={(event)=>{this.changeDetails('from_address_1', event.target.value)}} name={'Address Line 1*'} id={'promotionCode'}></CustomInput>
                  <CustomInput type={'text'} value={this.state.fromAddress2} onChange={(event)=>{this.changeDetails('from_address_2', event.target.value)}} name={'Address Line 2'} id={'promotionCode'}></CustomInput>
                  <CustomInput type={'text'} value={this.state.fromCity} onChange={(event)=>{this.changeDetails('from_city', event.target.value)}} name={'City*'} id={'promotionCode'}></CustomInput>
                  <CustomInput type={'text'} value={this.state.customerCompany} onChange={(event)=>{this.changeDetails('sender_company', event.target.value)}} name={'Contact Name*'} id={'promotionCode'}></CustomInput>
                  <CustomInput type={'text'} value={this.state.customerPhone} onChange={(event)=>{this.changeDetails('sender_phone', event.target.value)}} name={'Phone*'} id={'promotionCode'}></CustomInput>
                  <CustomInput type={'text'} value={this.state.fromInstructions} onChange={(event)=>{this.changeDetails('from_instructions', event.target.value)}} name={'Instructions'} id={'promotionCode'}></CustomInput>
                  <CustomInput type={'email'} value={this.state.customerEmail} onChange={(event)=>{this.changeDetails('sender_email', event.target.value)}} name={'Booker Email*'} id={'promotionCode'}></CustomInput>

                </div>



                <div className="booking-3-main-booking-form-column-2">

                  <b style={{color:"#787878"}}>Delivery:</b><br/>


                  <CustomInput type={'text'} value={this.state.toName} onChange={(event)=>{this.changeDetails('receiver_name', event.target.value)}} name={'Name/ Company*'} id={'promotionCode'}></CustomInput>


                  <CustomInput type={'text'} value={this.state.toAddress1} onChange={(event)=>{this.changeDetails('receiver_address_1', event.target.value)}} name={'Address Line 1*'} id={'promotionCode'}></CustomInput>
                  <CustomInput type={'text'} value={this.state.toAddress2} onChange={(event)=>{this.changeDetails('receiver_address_2', event.target.value)}} name={'Address Line 2'} id={'promotionCode'}></CustomInput>
                  <CustomInput type={'text'} value={this.state.toCity} onChange={(event)=>{this.changeDetails('receiver_city', event.target.value)}} name={'City*'} id={'promotionCode'}></CustomInput>
                  <CustomInput type={'text'} value={this.state.toContactName} onChange={(event)=>{this.changeDetails('receiver_contact_name', event.target.value)}} name={'Contact Name*'} id={'promotionCode'}></CustomInput>
                  <CustomInput type={'text'} value={this.state.toPhone} onChange={(event)=>{this.changeDetails('receiver_phone', event.target.value)}} name={'Phone*'} id={'promotionCode'}></CustomInput>
                  <CustomInput type={'text'} value={this.state.deliveryInstructions} onChange={(event)=>{this.changeDetails('delivery_instructions', event.target.value)}} name={'Instructions'} id={'promotionCode'}></CustomInput>

                </div>

              </div>
            </div>

            <div className="booking-form-3-2">
              <div className="main-booking-form-header-3">
                Billing Details
                <br/>
                <div style={{display:"inline-block", marginBottom:'0px',marginTop:'6px',marginLeft:'0vw', fontSize:'0.8rem'}} className="main-form-3-submit-button" onClick={this.autofillBillingDetailsFromCollection}>
                  Autofill from Collection Details
                </div>


                <div style={{display:"inline-block", marginBottom:'0px',marginTop:'6px', fontSize:'0.8rem'}} className="main-form-3-submit-button" onClick={this.autofillBillingDetailsFromDelivery}>
                  Autofill from Delivery Details
                </div>
              </div>

              <div className="booking-3-main-booking-form-column-1">

              <CustomInput type={'text'} value={this.state.billingName} onChange={(event)=>{this.changeDetails('billing_name', event.target.value)}} name={'Name/ Company*'} id={'promotionCode'}></CustomInput>
              <CustomInput type={'text'} value={this.state.billingAddress1} onChange={(event)=>{this.changeDetails('billing_address_1', event.target.value)}} name={'Address Line 1*'} id={'promotionCode'}></CustomInput>
              <CustomInput type={'text'} value={this.state.billingAddress2} onChange={(event)=>{this.changeDetails('billing_address_2', event.target.value)}} name={'Address Line 2'} id={'promotionCode'}></CustomInput>
              <CustomInput type={'text'} value={this.state.billingCity} onChange={(event)=>{this.changeDetails('billing_city', event.target.value)}} name={'City*'} id={'promotionCode'}></CustomInput>

              <CustomInput type={'text'} value={this.state.billingPostcode} onChange={(event)=>{this.changeDetails('billing_postcode', event.target.value)}} name={'Postcode*'} id={'promotionCode'}></CustomInput>
              <CustomInput type={'dropdown'} value={this.state.billingCountry} options={this.state.availableCountries} onChange={(event)=>{this.setState({billingCountry:event.target.value})}} name={'Country*'} id={'promotionCode'}></CustomInput>
              <CustomInput type={'text'} value={this.state.billingPhone} onChange={(event)=>{this.changeDetails('billing_phone', event.target.value)}} name={'Phone*'} id={'promotionCode'}></CustomInput>
              <CustomInput type={'text'} value={this.state.billingPhoneName} onChange={(event)=>{this.changeDetails('billing_phone_name', event.target.value)}} name={'Contact Name*'} id={'promotionCode'}></CustomInput>


              </div>

              <div className="booking-3-main-booking-form-column-2">


                <CustomInput type={'text'} value={this.state.billingVAT} onChange={(event)=>{this.changeDetails('billing_vat', event.target.value)}} name={'VAT Number'} id={'promotionCode'}></CustomInput>
                <br/>
                <div className="terms-label">
                  <input type="checkbox" id="checkbox1" name="checkbox1" checked={this.state.tailLiftNeeded} onChange={(event)=>{this.changeDetails('tail_lift_needed', event.target.value)}}></input>
                  <span> I require a taillift for my consignment</span>
                  <br/><br/>
                  <input type="checkbox" id="checkbox2" name="checkbox2" checked={this.state.tc2} onChange={(event)=>{this.changeDetails('tc2', event.target.value)}}></input>
                  <span > I can confirm that my consignment is of the disclosed dimensions & weight.</span>
                  <br/><br/>
                  <input type="checkbox" id="checkbox3" name="checkbox3" checked={this.state.tc3} onChange={(event)=>{this.changeDetails('tc3', event.target.value)}}></input>
                  <span>I accept that I cannot book a delivery to an Amazon Fulfilment Centre or <span style={{textDecoration:"underline"}} >any Distribution Centre</span> through this service.</span>
                  <br/><br/>
                  <input type="checkbox" id="checkbox4" name="checkbox4" checked={this.state.tc4} onChange={(event)=>{this.changeDetails('tc4', event.target.value)}}></input>
                  <span >I accept that deliveries to residential<br/>addresses must be booked in with
                  recipient, and this may affect the estimated transit time.</span>
                  <br/><br/>
                  <input type="checkbox" id="checkbox4" name="checkbox4" checked={this.state.saveAddress} onChange={(event)=>{this.changeDetails('saveAddress', event.target.value)}}></input>
                  <span >Save My Collection & Delivery Details (This may overwrite existing saves with the same postcode)</span>


                </div>



                <div className="booking-3-error">{this.state.error}</div>

                <div className="main-form-3-submit-button" onClick={this.submitDetails}>Submit
                  <img src={arrow} className="home-form-arrow-icon" ></img>
                </div>
              </div>



            </div>
        </div>

          <Footer></Footer>
        </div>
      )
    }else{
      return(
        <div className="loading-page-container">
          <div className="loading-page-loader-container">
            <ClipLoader
              size={160}
              color={'#FFFFFF'}
              loading={true}
            />
          </div>
        </div>
      )
    }

  }

}

export default BookingThree;
