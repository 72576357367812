import React from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import './index.css';
import Footer from '../../components/footer';
import Header from '../../components/header.js';
import CustomInput from '../../components/customInput.js';
import DatePicker from "react-datepicker";
import URLs from '../../constants/Api';
import { ClipLoader } from "react-spinners";

import arrow from '../../assets/arrow-icon.png';
import UnavailableIcon from '../../assets/unavailable.png';
import AvailableIcon from '../../assets/available.png';

import { loadStripe } from "@stripe/stripe-js";
import { Elements, ElementsConsumer } from "@stripe/react-stripe-js";

import SplitForm from '../../components/cardForm.js';





class BookingFour extends React.Component{



  constructor(props){
    super(props);




      this.state = {
        postcode1type:'text',
        postcode2type:'text',
        dataId:null,
        days:{},
        loading:false,
        chosenDate:'',
        error:'',
        cardNumber:'',
        cardCVC:'',
        cardExpiryMonth:'',
        cardExpiryYear:'',
        cardName:'',
        cardType:'VISA',

        paymentAmount: 0,

        pallets:[],
        collectionDate:0,

        tc1:true,
        tc2:true,
        tc3:true,
        mailingList:false,

        cards:[	{name:'Visa/ Visa Debit', code:'VISA'}, {name:'Mastercard', code:'MC'}, {name:'Laser (Ireland Only)', code:'Laser'}, {name:'UKDM (UK Only)', code:'UKDM'}],

        currencySymbols:{GBP:'£', EUR:'€'},
        currencyCode:null,

        loadingPromise:true,

        billingName:'',
        billingCity:'',
        billingAddress1:'',
        billingAddress2:'',
        billingCountry:'NO_SELECTION',
        billingPostcode:'',
        billingPhone:'',
        billingPhoneName:'',
        billingEmail:'',
        billingVAT:'',
        billingAmount:"",
        availableCountries:[],
        trackingCode:""

      }


    console.log(this.props.location.state);

    this.cardRef = React.createRef();

  }

  componentDidMount(){
    if(this.props.location.state){
      this.loadData();
      window.scrollTo(0, 0);
    }

    fetch(URLs.countriesURL,{
        method: "GET",
        headers: {
          'Accept':       'application/json',
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        })
        .then((res) => {
          if(res.status == 200){
            res.json().then((data)=>{

              let senderCountry = data[0].CODE;
              let receiverCountry = data[0].CODE;

              console.log(data);
              this.setState({loadingCountries: false, availableCountries:data, receiverCountry: receiverCountry});
            })
          }else{

            console.log(res);
            this.setState({
              error:"There was an error loading",
              loadingCountries:false
            });
            return;
          }
      });



    this.loadPromise();
  }

  /*  pk_live_5SnBVEG7ssm4BKeA1W8j1bN300ci2tN6je */
  /*  pk_test_51Gs86NLRJWNxm7nyslPgZnha7wwh5gigXq5ZCkbDp7tROyFWDbbFwjVu1r2tjIoWj9edXb8HnHQFjbLFpgEdKmR000TCxhBlk8 */

  loadPromise = async()=>{
    this.stripePromise = await loadStripe('pk_live_5SnBVEG7ssm4BKeA1W8j1bN300ci2tN6je');
    this.setState({loadingPromise:false});
  }

  loadData = ()=>{

  }

  stringifyPrice = (price) =>{

    let priceString = "";
    let euros = price.toString().split('.')[0];
    let cents = price.toString().split('.')[1];


    priceString = (euros.toString().length >= 4)? euros.substring(0,1)+","+euros.substring(1,euros.length): euros;

    if(cents == undefined) cents = 0;

    //cents = 12

    cents = parseInt(Math.round(parseFloat("0."+cents) * 100));

    console.log(cents);

    priceString = (cents.toString().length == 2)? priceString+"."+cents : priceString+"."+"0"+cents;

    return priceString;

  }

  encodeQueryData = (data)=>{
   const ret = [];
   for (let d in data)
     ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
   return ret.join('&');
 }

  submitDetails = async()=>{

    /* let expiryMonth = (this.state.cardExpiryMonth.toString().length == 1)? "0"+(this.state.cardExpiryMonth.toString()) : this.state.cardExpiryMonth.toString();


      if(this.state.cardNumber.toString().length != 16 || this.state.cardCVC.toString().length != 3 || expiryMonth.toString().length != 2 || this.state.cardExpiryYear.toString().length != 4 || this.state.cardName.length == 0){
        this.setState({error:"Your card details are not valid!"});
        return;
      }else if(!this.state.tc2){
        this.setState({error:"Please accept the terms & conditions!"});
        return;
      }else if(!this.state.tc3){
        this.setState({error:"Please accept the Privacy Policy!"});
        return;
      } */

      if(this.lastClick && ((new Date().getTime() - this.lastClick) < 3000)){
        console.log('DOUBLE CLICK');
        return;
      }

      this.lastClick = new Date().getTime();

      if(!this.state.intentId){
        await this.cardRef.current.handleSubmit();

        console.log(this.state.payload);

        if(this.state.payload.error){
          await this.setState({error:this.state.payload.error.message.toString()});
          console.log(this.state.payload.error);
          return;
        }
      }

      if(this.state.paymentAmount.length === 0 || this.state.paymentAmount < 1){
        await this.setState({error:"Please enter a valid payment amount."});
        return;
      }else if(this.state.billingName.length == 0 || this.state.billingPhoneName.length == 0 || this.state.billingAddress1.length == 0 || this.state.billingEmail.length == 0 || this.state.trackingCode.length == 0){
        await this.setState({error:"Please check your billing details and tracking code."});
        return;
      }

      await this.setState({loading:true});

      let bookingFourData = {
        AMOUNT:parseInt(this.state.paymentAmount*100),
        PAYMENT_METHOD:this.state.payload.paymentMethod.id,
        BILLING_NAME:this.state.billingName,
        BILLING_PHONE_NAME:this.state.billingPhoneName,
        BILLING_VAT:(this.state.billingVAT || 'NO EORI/ VAT'),
        BILLING_ADDRESS:this.state.billingAddress1,
        BILLING_EMAIL:this.state.billingEmail,
        TRACKING_CODE:this.state.trackingCode
      }

      console.log(bookingFourData);

      if(this.state.intentId){
        bookingFourData["INTENT_CODE"] = this.state.intentId;
      }


      fetch(URLs.customsPaymentURL,{
          method: "POST",
          body:JSON.stringify(bookingFourData),
          headers: {
            'Accept':       'application/json',
            'Content-Type': 'application/json'
          }

          })
          .then((res) => {
            if(res.status == 200){
              res.json().then(async(data)=>{

                if((!data.success) && data.auth_needed){
                  this.setState({intentId:data.message, redirectUrl:data.redirect_url});
                  console.log(data);
                  return;
                }
                await this.setState({bookingResponse:data, error:""});
                this.props.history.push({
                    pathname: '/booking/success',
                    state: data
                })
              })
            }else{
              res.json().then(async(data)=>{
                console.log(data);
              });
              this.setState({
                error:"There was an error with your card!",
                loading:false
              });
              return;
            }
        }).catch((error)=>{
          console.log(error);
        })

  }

  changeDetails = (details, data)=>{

    switch(details){
      case('card_number'):

        if(data.length <= 16){
        this.setState({cardNumber: data.replace(' ','')});
        }

      break;

      case('card_cvc'):
        if(data.length <= 3){
          this.setState({cardCVC: data.replace(' ','')});
        }
      break;

      case('tracking_code'):
          this.setState({trackingCode: data.replace(' ','')});
      break;

      case('card_expiry_month'):
        if(data.length <= 2){
          this.setState({cardExpiryMonth: data.replace(' ','')});
        }
      break;

      case('card_expiry_year'):
        if(data.length <= 4){
          this.setState({cardExpiryYear: data.replace(' ','')});
        }
      break;

      case('payment_amount'):
          this.setState({paymentAmount: data.replace(' ','')});
      break;

      case('card_name'):
          this.setState({cardName: data});
      break;

      case('card_type'):
          this.setState({cardType: data});
      break;

      case('billing_amount'):
          this.setState({billingAmount: data});
      break;

      case('billing_email'):
          this.setState({billingEmail: data});
      break;

      case('billing_phone_name'):
          this.setState({billingPhoneName: data});
      break;

      case('billing_name'):
          this.setState({billingName: data});
      break;

      case('billing_vat'):
          this.setState({billingVAT: data});
      break;

      case('billing_phone'):
          this.setState({billingPhone: data});
      break;

      case('billing_address_1'):
          this.setState({billingAddress1: data});
      break;

      case('tracking_code'):
          this.setState({trackingCode: data});
      break;

      case('paymentAmount'):
          this.setState({paymentAmount: data});
      break;

      case('tc2'):
          this.setState({tc2: !this.state.tc2});
      break;

      case('tc3'):
          this.setState({tc3: !this.state.tc3});
      break;

      case('mailing_list'):
          this.setState({mailingList: !this.state.mailingList});
      break;


    }


  }

  structureDate = (date)=>{
    return date.substr(8,2)+"/"+date.substr(5,2)+"/"+date.substr(0,4);
  }

  renderPriceNoVAT = ()=>{
    if(this.state.fromCountry == "UK" || this.state.toCountry == "UK"){
      return this.state.currencySymbols[this.state.currencyCode]+this.stringifyPrice(Math.round(((this.state.paymentAmountNoVAT.toString().length == 4)? (this.state.paymentAmountNoVAT - ((this.state.currencyCode == "GBP")? 0:0)).toString()+"0" : this.state.paymentAmountNoVAT - ((this.state.currencyCode == "GBP")? 0:0))*100)/100);
    }

    return this.state.currencySymbols[this.state.currencyCode]+this.stringifyPrice((this.state.paymentAmountNoVAT.toString().length == 4)? this.state.paymentAmountNoVAT.toString()+"0" : this.state.paymentAmountNoVAT);
  }

  renderVAT = ()=>{
    let customs = 0;

    if(this.state.fromCountry == "UK" || this.state.toCountry == "UK"){
      customs = (this.state.currencyCode == "GBP")? 90:90;
    }

    console.log(customs);

    return this.state.currencySymbols[this.state.currencyCode]+this.stringifyPrice((this.state.Vat.toString().length == 1)?  "0.00" : Math.round((this.state.Vat - customs) * 100) / 100);
  }

  renderCustoms = ()=>{

    let currencyAmount = (this.state.currencyCode == "GBP")? 90:90;
    if(this.state.fromCountry == "UK" || this.state.toCountry == "UK"){
      return(
        <tr className="price-display">
          <td style={{width:"50%"}}>Customs Clearance</td>
          <td style={{textAlign:"left", width:"50%"}}>{this.state.currencySymbols[this.state.currencyCode]}{currencyAmount+".00"}</td>
        </tr>
      )
    }
  }



  render(){
    if(!this.state.loading && !this.state.loadingPromise){
      return(
        <div>
          <Header></Header>

          <div className="content">
            <div className="payment-intro">
              <div className="summary-header">Pay Customs VAT & Duty Charges</div><br/>
              This section is for the payment of Customs-related charges only (i.e. VAT and/or Duty owed to Irish Revenue and related charges). You have been sent a link
              to this section by Transland in relation to a consignment or consignments being imported from or exported to GB. Please complete the fields below and
              pre-pay using credit or debit card.<br/><br/>
              <b>PLEASE NOTE:</b><br/>

              The amount quoted by Transland is an ESTIMATED amount provided in order to customs clear the consignment. This amount may be adjusted
              slightly up or down by Revenue, and thus may require either a secondary payment or refund.<br/>
              <ul>
                <li> Your consignment will not be delivered until full and final payment of Customs charges is received by Transland.</li>
                <li> Any incorrect or missing information will result in delays to your consignment.</li>
                <li> For security purposes, we do not retain credit / debit card details on this website.</li>
              </ul>
            </div>
            <div className="booking-form-4">
              <div className="main-booking-form-columns">
                <div className="booking-3-main-booking-form-column-1">
                  <CustomInput type={'text'} value={this.state.trackingCode} onChange={(event)=>{this.changeDetails('tracking_code', event.target.value)}} name={'Tracking ID*'} id={'promotionCode'}></CustomInput>
                  <CustomInput type={'text'} value={this.state.billingName} onChange={(event)=>{this.changeDetails('billing_name', event.target.value)}} name={'Billing Name*'} id={'promotionCode'}></CustomInput>
                  <CustomInput type={'text'} value={this.state.billingAddress1} onChange={(event)=>{this.changeDetails('billing_address_1', event.target.value)}} name={'Billing Address*'} id={'promotionCode'}></CustomInput>

                </div>
                <div className="booking-3-main-booking-form-column-2">
                  <CustomInput type={'text'} value={this.state.billingPhoneName} onChange={(event)=>{this.changeDetails('billing_phone_name', event.target.value)}} name={'Contact Name*'} id={'promotionCode'}></CustomInput>
                  <CustomInput type={'text'} value={this.state.billingEmail} onChange={(event)=>{this.changeDetails('billing_email', event.target.value)}} name={'Email Address*'} id={'promotionCode'}></CustomInput>
                  <CustomInput type={'text'} value={this.state.billingVAT} onChange={(event)=>{this.changeDetails('billing_vat', event.target.value)}} name={'EORI/ VAT Number'} id={'promotionCode'}></CustomInput>
                </div>

                <CustomInput type={'number'} value={this.state.paymentAmount} onChange={(event)=>{this.changeDetails('payment_amount', event.target.value)}} name={'Payment Amount in Euro (Advised By Transland Customs Dept.)*'} id={'promotionCode'}></CustomInput>

              </div>
            </div>

            <div className="booking-form-4">

              <br/>
              <Elements stripe={this.stripePromise}>
                <ElementsConsumer>
                  {({elements, stripe}) => (
                    <SplitForm context={this} ref={this.cardRef} elements={elements} stripe={stripe} />
                  )}
                </ElementsConsumer>
              </Elements>

              <br/><br/>


              <br/>

              Please do not refresh the screen while your payment is being processed.


              <div className="booking-4-error">{this.state.error}</div>

              <div className="payment-submit-button" onClick={this.submitDetails}>Pay & Order
                <img src={arrow} className="home-form-arrow-icon" ></img>
              </div>
            </div>
        </div>

          <Footer></Footer>
        </div>
      )
    }else if(this.state.intentId){
      return(
        <div className="loading-page-container">
          <div className="loading-page-loader-container">
            <iframe src={this.state.redirectUrl} style={{width:'60vw',height:'50vh', marginBottom:'40px'}} />
            <div style={{color:"white",fontSize:"1.4rem"}}>Please confirm payment authorisation with your bank above, then resubmit below.</div>
            <div className="payment-submit-button" style={{border:"2px solid white"}} onClick={this.submitDetails}>Resubmit</div>
          </div>
        </div>
      )

    }else{
      return(
        <div className="loading-page-container">
          <div className="loading-page-loader-container">
            <ClipLoader
              size={160}
              color={'#FFFFFF'}
              loading={true}
            />
          </div>
        </div>
      )
    }

  }

}

export default BookingFour;
