import React from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import './index.css';
import Footer from '../../components/footer';
import Header from '../../components/header.js';

import translandTrucks from '../../assets/transland-trucks.jpg';
import kieran from '../../assets/kieran.jpg';
import patrick from '../../assets/patrick.jpg';
import helen from '../../assets/helen.jpg';
import kelvin from '../../assets/kelvin.jpg';
import mandy from '../../assets/mandy.png';
class AboutUs extends React.Component{

  constructor(props){
    super(props);

    this.state = {
                  loading:false,
                };

  }

  componentDidMount = ()=>{
    window.scrollTo(0, 0);
  }

  render(){
    return(
      <div>
        <Header></Header>

        <div className="content">

          <div className="about-content-image"></div>

          <div className="bread-trail"><a href="../../">Home</a> / <a href="../about">About Us</a> / <a href="/">Management Team</a></div>

          <div className="content-header" style={{fontSize:"1.6vw"}}>Management Team</div>

          <div className="team-member">
            <img src={kieran} className="team-member-image" />

            <div className="team-member-text">
              <div className="team-member-header">Managing Director: Kieran Conlon, MILT</div>

              <div className="team-member-bio">
                Kieran has been involved in the freight forwarding business for over 35 years.
                During this time Kieran, who has worked in both Sales and Operations, gained considerable knowledge of all European roadfreight sectors, particularly the UK and German markets, as well as the Far Eastern deep-sea liner business. Kieran has been Managing Director since the company’s formation in 1993, and has always played a hands-on role in the company’s development. A true innovator, Kieran was the driving force behind the online booking facility available on the Transland website, and is responsible for Transland’s repositioning as a technology leader within the transport industry.
              </div>
            </div>
          </div>

          <div className="team-member">
            <img src={patrick} className="team-member-image" />

            <div className="team-member-text">
              <div className="team-member-header">Financial Director: Patrick Dowling, FCCA</div>

              <div className="team-member-bio">
                Patrick is a Chartered Certified Accountant. He has worked with Transland International for over 20 years, and is responsible for directing the financial and strategic decisions of the Group. Patrick was heavily involved in the development and implementation of the Group’s Corporate Performance Management (CPM) system, and also with the restructuring
                 of the Group’s business divisions. He continues to manage the company’s network infrastructure.
              </div>
            </div>
          </div>

          <div className="team-member">
            <img src={helen} className="team-member-image" />

            <div className="team-member-text">
              <div className="team-member-header">Administration Director: Helen Lee</div>

              <div className="team-member-bio">
                Helen joined the company in 1997 as Personal Assistant to the Managing Director. Since then she has undertaken various roles, including Quality, Finance, Operations, Health & Safety and IT. Helen was directly responsible for the implementation of the Group’s Corporate Performance Management System, and also for the acquisition and maintenance of the company’s quality standard (ISO 9001:2008). She continues to direct and oversee the Group’s administrative procedures to ensure achievement of performance targets and guarantee customer satisfaction.
              </div>
            </div>
          </div>

          <div className="team-member">
            <img src={kelvin} className="team-member-image" />

            <div className="team-member-text">
              <div className="team-member-header">UK Business Development & Operations Director: Kelvin Jones</div>

              <div className="team-member-bio">
                Kelvin is Business Development and Operations Director of Transland International (UK) Ltd, and is responsible for managing and developing the UK facility in Birkenhead. Prior to joining Transland, Kelvin was self-employed in a Logistics Consultancy role. In his earlier career he worked in the Logistics Sector holding Management positions for TNT,
                Bax Global and Europa. Kelvin has over 30 years experience in the field of transport and logistics.
              </div>
            </div>
          </div>

          <div className="team-member">
            <img src={mandy} className="team-member-image" />

            <div className="team-member-text">
              <div className="team-member-header">Sales Director: Mandy Spain</div>

              <div className="team-member-bio">
                Mandy joined Transland in 2005, with an extensive background in Hospitality & Tourism Management. Her original role as Account Manager focused on establishing new business and developing long-term client relationships. Since becoming Sales & Operations Director of Transland in 2014, Mandy is responsible for maximizing growth opportunities,
                customer satisfaction and operational performance through the development and implementation of key strategies.
              </div>
            </div>
          </div>




        </div>
        <Footer></Footer>
      </div>
    )
  }

}

export default AboutUs;
