import React from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import './index.css';
import Footer from '../../components/footer';
import Header from '../../components/header.js';

import shippingService from '../../assets/shipping-service.png';
import podCapture from '../../assets/pod-capture.png';
import gpsTracking from '../../assets/gps-tracking.png';

import arrow from '../../assets/arrow-icon.png';

class Technology extends React.Component{

  constructor(props){
    super(props);

    this.state = {
                  loading:false,
                };

  }

  componentDidMount = ()=>{
    window.scrollTo(0, 0);
  }

  render(){
    return(
      <div>
        <Header></Header>

        <div className="technology-image"></div>

        <div className="bread-trail"><a href="../">Home</a> / <a href="/">Downloads</a></div>

        <div className="content">

          <div className="technology-content-header" style={{fontSize:"4vh"}}>Downloads</div>

          <div className="technology-content-text" style={{fontSize:"2.2vh"}}>
            <a className="template-link" href="/online-booking-policy.pdf"><span style={{fontSize:"1.1rem",color:"#7009de"}}>Transland Online Shipping Policy</span></a><br/>
            <a className="template-link" href="/irha_conditions.pdf"><span style={{fontSize:"1.1rem",color:"#7009de"}}>IRHA Conditions (Irish Shipments)</span></a><br/>
            <a className="template-link" href="/rha_conditions.pdf"><span style={{fontSize:"1.1rem",color:"#7009de"}}>RHA Conditions (UK Shipments)</span></a><br/>
            <a className="template-link" href="/cmr_conditions.pdf"><span style={{fontSize:"1.1rem",color:"#7009de"}}>CMR Conditions (European Shipments)</span></a><br/>
            <a className="template-link" href="/privacy-policy"><span style={{fontSize:"1.1rem",color:"#7009de"}}>Transland Privacy Policy</span></a><br/><br/>


            <a href="/ROI_Exporter.pdf"><span style={{fontSize:"1.1rem",color:"#7009de"}}>ROI Exporter Guide – Forms Required</span></a><br/>
            <a href="/Direct_Rep_GB.pdf"><span style={{fontSize:"1.1rem",color:"#7009de"}}>Transland Direct Representation Authorisation Form – GB (updated September 2022)</span></a><br/>
            <a href="/C1207N.pdf"><span style={{fontSize:"1.1rem",color:"#7009de"}}>UK HMRC Deferment Request Form (C1207N)</span></a><br/>
            <a href="/ROI_Importer_Guide.pdf"><span style={{fontSize:"1.1rem",color:"#7009de"}}>ROI Importer Guide – Forms Required</span></a><br/>
            <a href="/Direct_Rep_IE.pdf"><span style={{fontSize:"1.1rem",color:"#7009de"}}>Transland Direct Representation Authorisation Form – IE</span></a><br/>
            <a href="/Revenue_Deferment.pdf"><span style={{fontSize:"1.1rem",color:"#7009de"}}>Irish Revenue Deferment Request Form (AEP5a)</span></a><br/>
            <a href="/Commercial_Invoice_Template.xlsx"><span style={{fontSize:"1.1rem",color:"#7009de"}}>Commercial Invoice Template</span></a><br/>

          </div>

        </div>
        <Footer></Footer>
      </div>
    )
  }

}

export default Technology;
